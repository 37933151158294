<template>
  <div>
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-piutang')"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center flex-column"
          v-if="details.items && details.items.length > 0"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ details.items[0].subject.name || '-' }}
          </h1>
          <small class="text-dark text-darken-5 fw-bold-500 size12">
            Telp : {{ details.items[0].subject.phone_number || '-' }}
          </small>
        </b-col>
      </b-row>
    </header>
    <b-container fluid class="mt-5 pt-5">
      <b-row
        class="mx-0 mt-1 justify-content-center"
      >
        <b-col
          class=""
          cols="12"
        >
          <b-table
            class="mb-0"
            responsive="sm"
            :fields="fields"
            :items="items"
          >
            <template #head(action)="">
              <div class="custom-control custom-checkbox">
                <input
                  id="check-all"
                  class="custom-control-input"
                  type="checkbox"
                  :value="checkAll"
                  :checked="selectAllValue && selectAllValue.length"
                  @change="checkedAll(items)"
                >
                <label
                  for="check-all"
                  class="custom-control-label"
                >
                </label>
              </div>
            </template>
            <template #head(branch)="">
              <span>Cabang</span>
            </template>
            <template #head(no_invoice)="">
              <span>No. Invoice</span>
            </template>
            <template #head(date_invoice)="">
              <span>Tgl Invoice</span>
            </template>
            <!-- <template #head(date_delivery_number)="">
              <span>Tgl Surat Jalan Supplier</span>
            </template> -->
            <template #head(due_date)="">
              <span>Tgl Jatuh Tempo</span>
            </template>
            <template #head(total)="">
              <span>Jumlah</span>
            </template>
            <template #head(factur_number_tax)="">
              <span>Sisa Piutang</span>
            </template>
            <template #head(amount)="">
              <span>Bayar (Rp)</span>
            </template>

            <!-- A custom formatted column -->
            <template #cell(action)="data">
              <b-form-checkbox
                v-model="formPayload.items[data.index].uuid"
                :value="data.item.uuid"
              />
            </template>

            <!-- A custom formatted column -->
            <template #cell(no_invoice)="data">
              <span>
                {{ data.item.no_invoice }}
              </span>
            </template>
            <template #cell(total)="data">
              <span>
                {{ data.item.total | formatAmount }}
              </span>
            </template>
            <template #cell(factur_number_tax)="data">
              <span>
                {{ data.item.factur_number_tax | formatAmount }}
              </span>
            </template>
            <template #cell(amount)="data">
              <!-- v-b-tooltip.hover.bottom="'Nilai pembayaran parsial terlalu kecil!'" -->
              <b-form-group
                label=""
                label-for="v-bayar"
                class="custom__form--input mb-0"
              >
                <money
                  id="v-bayar"
                  v-model="formPayload.items[data.index].amount"
                  v-bind="money"
                  :disabled="!formPayload.items[data.index].uuid"
                  placeholder="Bayar"
                  class="custom__input border-8 pl-1"
                  @input="emitAmountInput(data.index, formPayload.items[data.index].amount)"
                />
              </b-form-group>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <div 
        class="footer__purchase p-2" 
        style="position: fixed;"
      >
        <b-row class="mx-0 align-items-center">
          <b-col lg="6">
            <h5 class="mb-0 text-dark fw-bold-600">
              Total Nilai Piutang {{ details.piutang_total - details.piutang_paid | formatAmount }}
            </h5>
          </b-col>
          <b-col
            lg="6"
            class="text-right"
          >
            <div class="d-flex justify-content-end">
              <b-button
                v-if="checkPermission('simpan pembayaran piutang')"
                class="btn-primary py-2 size14 mt-0 border-8 mr-1"
                @click="savePayment"
              >
                <span>Simpan</span>
              </b-button>
              <b-button
                class="btn-primary px-5 py-2 size14 mt-0 border-8"
                :disabled="formPayload.items.map(e => e.uuid).every(id => !id)"
                @click="acceptPayment"
              >
                <span class="px-3">Terima Pembayaran</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <ModalPayment
      :amount="amountToPass"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BContainer, BTable, BFormGroup, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import {
  removeArray, preformatFloat, checkPermission, successNotification,
} from '@/auth/utils'

import ModalPayment from '@/components/Debt/Modal/ModalPayment.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormCheckbox,
    ModalPayment
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      voucher_coupon: '',
      fields: ['action', 'branch' , 'no_invoice', 'date_invoice', 'due_date', 'total', 'factur_number_tax', 'amount'],
      items: [],
      details: {},
      formPayload: {
        items: [],
        payment_method: '',
      },
      selectAllValue: [],
      checkAll: false,
      total: 0,
      customer_uuid: localStorage.getItem('customer_supplier_uuid'),
    }
  },
  computed: {
    amountToPass() {
      return this.formPayload.items.reduce((total, item) => {
      if (item.uuid) {
        return total + parseFloat(item.amount || 0);
      }
      return total;
    }, 0);
    },
  },
  setup() {
    return {
      removeArray, preformatFloat, checkPermission, successNotification,
    }
  },
  watch: {
    '$store.state.customer.detailPiutang': {
      handler(value) {
        this.details = value
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        let total = 0
        for (let index = 0; index < value.items.length; index++) {
          // const element = value.items[index]
          // total += parseInt(element.amount)
          const element = value.items[index]
          element.is_checked = element.uuid ? 1 : 0
          const formatedAmount = parseFloat(preformatFloat(element.amount.toString()))
          // value.items[index].amount = value.items[index].amount > element.unpaid ? element.unpaid : value.items[index].amount
          if (element.uuid) {
            total += formatedAmount

            if (formatedAmount > element.unpaid) {
              // this.formPayload.items[index].amount = element.unpaid
              setTimeout(() => {
                formatedAmount = element.unpaid
              }, 300);
            }
            // console.log(this.formPayload.items[index]);
          }
        }
        this.total = total
        this.$store.commit('supplier/setTotal', total)
        this.$store.commit('customer/setFormPayloadPiutang', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.details = this.$store.state.customer.detailPiutang
    for (let index = 0; index < this.$store.state.customer.detailPiutang.items.length; index++) {
      const element = this.$store.state.customer.detailPiutang.items[index]
      this.formPayload.items.push({
        uuid: element.draft && element.draft.is_checked == 1 ? element.uuid : null,
        amount: element.unpaid,
        is_checked: element.draft && element.draft.is_checked,
      })
      // no_invoice', 'date_invoice', 'date_delivery_number', 'due_date', 'total', 'factur_number_tax', 'amount'
      this.items.push({
        index,
        uuid: element.uuid,
        branch: element.branch.name,
        due_date: element.due_date,
        no_invoice: element.reference?.invoice_number,
        date_invoice: element.reference?.date,
        total: element.amount,
        factur_number_tax: element.unpaid,
      })
    }
  },
  methods: {
    checkedAll(items) {
      const filteredArray = this.getUniqueListBy(items, 'uuid')
      if (!this.checkAll) {
        for (let index = 0; index < filteredArray.length; index++) {
          const element = filteredArray[index];
          this.formPayload.items[index].uuid = element.uuid
        }
        this.selectAllValue = filteredArray
        this.checkAll = true
      } else {
        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];
          this.formPayload.items[index].uuid = ''
        }
        this.selectAllValue = []
        this.checkAll = false
      }
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    acceptPayment() {
      // const data = this.formPayload.items.filter(e => e.uuid !== null)
      // console.log(data)
      this.$bvModal.show('modal-payment')
    },
    emitAmountInput(index, amount) {
      this.$emit('amount-input', { index, amount });
    },
    preparePayload() {
      const form = new FormData()
      const filteredData = this.formPayload.items.filter(e => e.uuid)
      // eslint-disable-next-line no-restricted-syntax, no-plusplus
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index]
        // console.log(element, ' DEDOR');
        if (element.uuid) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in element) {
            // eslint-disable-next-line no-prototype-builtins
            if (element.hasOwnProperty(key) && element !== '') {
              const items = element[key]
              form.append(`items[${index}][${key}]`, items)
            }
          }
        }
      }
      return form
    },
    async savePayment() {
      try {
        delete this.formPayload.payment_method

        const form = this.preparePayload()

        const response = await this.$http.post(`/piutang/${this.customer_uuid}/add-payment-draft`, form)
        if (response.status === 200) {
          successNotification(this, 'Success', 'Piutang berhasil disimpan ke draft')
          this.$bvModal.hide('modal-piutang')
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
.text-blue {
  color: #004AD7;
}
</style>
